import {
	DeleteOutline,
	EditOutlined,
	MoreHorizOutlined,
	PowerSettingsNewOutlined,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import { OrgMessageButton } from 'components/pages/network/org/OrgMessageButton/OrgMessageButton';
import { UserMessageButton } from 'components/pages/network/user/UserMessageButton/UserMessageButton';
import { Status, useOrgAssociatesQuery } from 'components/pages/org/associates/hooks';
import {
	useDeactivateAssociateModal,
	useReactivateAssociateModal,
} from 'components/pages/org/associates/modals/activation';
import {
	useAssociateInvitationModal,
	useDeleteAssociateInvitationModal,
} from 'components/pages/org/associates/modals/invitation';
import { useUpdateAssociateModal } from 'components/pages/org/associates/modals/update';
import { useOrgId } from 'components/pages/org/outlet';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { DeactivateIcon } from 'components/ui/icons';
import { PageContent, PageTitle } from 'components/ui/page';
import { Search } from 'components/ui/search';
import SortableColumnCell from 'components/ui/sortable-column-cell';
import { AssociateOrInvitation, EmblemEntityType } from 'middleware-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageError } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { DEFAULTPAGESIZES } from 'utils/theme';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';

/**
 *  Associate row props.
 */
type AssociateRowProps = {
	orgId: string;
	associate: AssociateOrInvitation;
};

export enum OrgMessageType {
	Organization = 'Organization',
	Direct = 'Direct',
}

/**
 * This defines all the colors used for the status text.
 * It is a mapping of Status -> MUI Theme color (see Theme.tsx)
 * @type {Record<Status, string>} Map of Status to MUI theme color string.
 */
const statusColor: Record<Status, string> = {
	Invited: 'neutral.200',
	Active: 'primary.dark',
	Inactive: 'error.dark',
	Declined: 'error.dark',
};

/**
 * AssociateRow - A single row on the organization associates table.
 *
 * @param {AssociateRowProps} props
 * @returns
 */
const AssociateRow = ({ orgId, associate }: AssociateRowProps) => {
	const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
	const { user } = useSession();
	const { handle } = useParams<{
		handle: string;
	}>();
	const associateUserId = associate.userId ? associate.userId : '';

	const currentUser = user?.userId === associate.userId;
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const canUpdate =
		hasAssociateUserPermission(Permission.Org_Assoc_U) ||
		hasSiteUserPermission(Permission.Site_OrgAssoc_U);

	const canMessageAsOrg = hasAssociateUserPermission(Permission.Org_Messages_U);

	const canDelete =
		hasAssociateUserPermission(Permission.Org_Assoc_Inv_D) ||
		hasSiteUserPermission(Permission.Site_OrgAssocInv_D);

	const handleAssociateActionsMenuClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => setAnchorEl(event.currentTarget);

	const { updateAssociate } = useUpdateAssociateModal({
		orgId,
		associate,
	});

	const { openDeleteInvitation } = useDeleteAssociateInvitationModal(
		orgId,
		associate.invitationId!,
		associate.displayName
	);

	const { openDeactivateAssociate } = useDeactivateAssociateModal(
		orgId,
		associate.associateId!,
		associate.displayName
	);

	const { reactivateAssociate } = useReactivateAssociateModal({
		orgId,
		associate,
	});

	const handleUpdateAssociateClick = (): void => {
		setAnchorEl(null);
		updateAssociate();
	};

	const handleDeactivateAssociateClick = (): void => {
		setAnchorEl(null);
		openDeactivateAssociate();
	};

	const handleReactivateAssociateClick = (): void => {
		setAnchorEl(null);
		reactivateAssociate();
	};

	const displayOnProfile =
		associate.status === Status.Active ? (associate.displayOnProfile ? 'Yes' : 'No') : 'N/A';

	const isUserDeactivated = associate.deactivated;

	const showUserMessageButton =
		associate.status === Status.Active &&
		user.userId !== associate.userId &&
		!isUserDeactivated;

	const showOrganizationMessageButton =
		associate.status === Status.Active && !isUserDeactivated && canMessageAsOrg;

	return (
		<TableRow>
			<TableCell>
				<Stack flexDirection="row" gap={2} alignItems="center">
					{associate.userId && associate.status !== 'Invited' ? (
						<UserEmblemAvatar id={associate.userId} />
					) : (
						<Box sx={{ width: 40 }} />
					)}
					<Stack>
						<Box>{associate.externalUserDisplayName}</Box>
						<Typography variant="subtitle2">
							{associate.displayName != associate.externalUserDisplayName &&
								associate.displayName}
						</Typography>
					</Stack>
				</Stack>
			</TableCell>
			<TableCell>
				<Stack flexDirection="row" alignItems="center" gap={1}>
					{associate.status === 'Invited' && associate.emailAddress}
					{showUserMessageButton && (
						<UserMessageButton
							entityId={associateUserId}
							entityType={EmblemEntityType.User}
							handle={handle}
						/>
					)}
					{showOrganizationMessageButton && (
						<OrgMessageButton
							entityId={associateUserId}
							entityType={EmblemEntityType.User}
							handle={handle}
							orgId={orgId}
						/>
					)}
				</Stack>
			</TableCell>
			<TableCell>{associate.organizationRoleName}</TableCell>
			<TableCell
				sx={{
					color: statusColor[associate.status],
				}}>
				{associate.status}
			</TableCell>
			<TableCell>{displayOnProfile}</TableCell>
			<TableCell size="small">
				{canUpdate && associate.associateId && associate.status != 'Invited' && (
					<>
						<IconButton onClick={handleAssociateActionsMenuClick}>
							<MoreHorizOutlined />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={(): void => setAnchorEl(null)}>
							{associate.status === 'Active' && (
								<MenuItem onClick={handleUpdateAssociateClick}>
									<ListItemIcon>
										<EditOutlined />
									</ListItemIcon>
									<ListItemText>Update Associate</ListItemText>
								</MenuItem>
							)}
							{associate.status === 'Active' && !currentUser && (
								<MenuItem onClick={handleDeactivateAssociateClick}>
									<ListItemIcon>
										<DeactivateIcon />
									</ListItemIcon>
									<ListItemText>Deactivate Associate</ListItemText>
								</MenuItem>
							)}
							{associate.status === 'Inactive' && !currentUser && (
								<MenuItem onClick={handleReactivateAssociateClick}>
									<ListItemIcon>
										<PowerSettingsNewOutlined />
									</ListItemIcon>
									<ListItemText>Reactivate Associate</ListItemText>
								</MenuItem>
							)}
						</Menu>
					</>
				)}
				{canDelete && associate.invitationId && (
					<>
						<IconButton onClick={handleAssociateActionsMenuClick}>
							<MoreHorizOutlined />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={(): void => setAnchorEl(null)}>
							<MenuItem onClick={openDeleteInvitation}>
								<ListItemIcon>
									<DeleteOutline />
								</ListItemIcon>
								<ListItemText>Delete Invitation</ListItemText>
							</MenuItem>
						</Menu>
					</>
				)}
			</TableCell>
		</TableRow>
	);
};

/**
 * OrgAssociates contains the entire organization associates page.
 *
 * @returns
 */
export const OrgAssociatesPage = () => {
	const orgId = useOrgId();

	const [page, setPage] = useState<number>(0);
	const [isAsc, setIsAsc] = useState<boolean>(true);
	const [sortBy, setSortBy] = useState<string>('status');
	const [searchFor, setSearchFor] = useState('');
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const [pageSize, setPageSize] = useState<number>(DEFAULTPAGESIZES[0]);
	const { totalCount, associates, loading, refetch, error } = useOrgAssociatesQuery(
		orgId,
		pageSize,
		page,
		sortBy,
		isAsc ? 'Ascending' : 'Descending',
		searchFor
	);

	const canInvite =
		hasAssociateUserPermission(Permission.Org_Assoc_Inv_C) ||
		hasSiteUserPermission(Permission.Site_OrgAssocInv_C);

	if (error) throw PageError;

	const rows: React.JSX.Element[] = associates.map((associate, i) => (
		<AssociateRow key={i} orgId={orgId} associate={associate} />
	));

	/**
	 * On page changed.
	 *
	 * @param {number} p
	 */
	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	/**
	 * On page size changed.
	 *
	 * @param {React.ChangeEvent<{ value: unknown; }>} e
	 */
	const handlePageSizeChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		const value = parseInt(e.target.value, DEFAULTPAGESIZES[0]);
		setPageSize(value);
		setPage(0);
		refetch();
	};

	/**
	 * Handle column sorting pressed.
	 *
	 * @param {string} dataKey
	 */
	const handleSortClick = (dataKey: string): void => {
		if (dataKey === sortBy) {
			setIsAsc(!isAsc);
		} else {
			setSortBy(dataKey);
		}
	};

	/**
	 * on Search (enter or button pressed).
	 *
	 * @param {string} str
	 */
	const handleSearch = (str: string): void => {
		setSearchFor(str);
	};

	/**
	 * When the associate invitation modal is closed and it is successful
	 * clear out the search, sort and page states and
	 * sort by id.
	 *
	 * @param {boolean} success
	 */
	const handleInviteAssociateClose = (): void => {
		setSortBy('status');
		setIsAsc(true);
		setSearchFor('');
		setPage(0);
		refetch();
	};

	const { openInvitation } = useAssociateInvitationModal(orgId, handleInviteAssociateClose);

	return (
		<PageContent>
			<PageTitle title="Associates" />
			<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				<CardHeader
					title="Associates"
					action={
						<Stack direction="row" spacing={1}>
							<Search onChange={handleSearch} />
							{canInvite && (
								<Button
									onClick={(): void => openInvitation()}
									variant="contained"
									color="primary">
									Invite Associate
								</Button>
							)}
						</Stack>
					}></CardHeader>
				<CardContent
					sx={{
						flex: '1 1 auto',
						overflow: 'hidden',
					}}>
					<TableContainer sx={{ maxHeight: '100%' }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<SortableColumnCell
										column="displayName"
										onSort={handleSortClick}
										active={'displayName' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Name
									</SortableColumnCell>
									<SortableColumnCell
										column="emailAddress"
										onSort={handleSortClick}
										active={'emailAddress' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Contact
									</SortableColumnCell>
									<SortableColumnCell
										column="organizationRoleName"
										onSort={handleSortClick}
										active={'organizationRoleName' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Role
									</SortableColumnCell>
									<SortableColumnCell
										column="status"
										onSort={handleSortClick}
										active={'status' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Status
									</SortableColumnCell>
									<SortableColumnCell
										column="displayOnProfile"
										onSort={handleSortClick}
										active={'displayOnProfile' === sortBy}
										direction={isAsc ? 'asc' : 'desc'}>
										Display on Profile?
									</SortableColumnCell>
									<TableCell size="small" style={{ width: '10%' }}></TableCell>
								</TableRow>
							</TableHead>
							{loading ? (
								<SkeletonRows rows={pageSize} />
							) : (
								<TableBody>{rows}</TableBody>
							)}
						</Table>
					</TableContainer>
				</CardContent>
				<CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
					<TablePagination
						component={Stack}
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
						count={totalCount}
						page={page}
						rowsPerPage={pageSize}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handlePageSizeChange}
					/>
				</CardActions>
			</Card>
		</PageContent>
	);
};

// Will generate however many placeholders depending on page size
// Actual number of rows may be fewer
const SkeletonRows = (props: { rows: number }): React.JSX.Element => (
	<TableBody>
		{Array(props.rows)
			.fill(null)
			.map((_, i) => {
				return (
					<TableRow key={i}>
						<TableCell colSpan={6}>
							<Skeleton animation="wave" height={'2em'} />
						</TableCell>
					</TableRow>
				);
			})}
	</TableBody>
);
