import { gql, useQuery } from '@apollo/client';
import { ArrowDropDownOutlined, DomainOutlined } from '@mui/icons-material';
import {
	Alert,
	Button,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { Query, QueryAffiliatedOrganizationsArgs } from 'middleware-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'utils/session';

/* Query for list of orgs the current user belongs to/ is affiliated with. */
export const AFFILIATED_ORGS = gql`
	query affiliatedOrganizations($userId: ID!) {
		affiliatedOrganizations(userId: $userId) {
			id
			displayName
			handle
		}
	}
`;

export const useAffiliatedOrgsQuery = (userId: string | undefined) => {
	const { loading, data, error } = useQuery<
		Pick<Query, 'affiliatedOrganizations'>,
		QueryAffiliatedOrganizationsArgs
	>(AFFILIATED_ORGS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			userId: userId as string,
		},
		skip: !userId,
	});

	return {
		error,
		loading,
		organizations: data?.affiliatedOrganizations ?? [],
	};
};

/**
 * Returns the organization dropdown in the top navigation bar.
 * @returns
 */
export const OrgDropDown = () => {
	const { user } = useSession();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { error, loading, organizations } = useAffiliatedOrgsQuery(user.userId);
	if (loading || (organizations.length <= 0 && !error)) return <></>;

	const handleClick = (path: string) => {
		setAnchorEl(null);
		navigate(path);
	};

	return (
		<>
			<Button
				onClick={(el) => setAnchorEl(el.currentTarget)}
				sx={{
					'p': '6px 6px 6px 10px',
					'border': '1px solid',
					'borderColor': anchorEl ? 'primary.main' : 'neutral.200',
					'borderRadius': '5px',
					'cursor': 'pointer',
					'backgroundColor': anchorEl ? 'neutral.100' : undefined,
					':hover': {
						backgroundColor: 'neutral.100',
					},
					'display': 'flex',
					'justifyContent': 'space-between',
					'gap': 1,
					':focus-visible': {
						backgroundColor: 'neutral.100',
					},
				}}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<DomainOutlined sx={{ color: 'neutral.500' }} />
					<Typography
						noWrap
						flex={1}
						sx={{
							display: { xs: 'block', sm: 'none', md: 'block' },
						}}>
						My Organizations
					</Typography>
				</Stack>
				<ArrowDropDownOutlined />
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				disableAutoFocus
				disableAutoFocusItem
				disableRestoreFocus
				onClose={() => setAnchorEl(null)}
				sx={{ '& .MuiMenu-list': { maxHeight: 440 } }}>
				{error ? (
					<MenuItem>
						<Alert severity="error">Organizations failed to load</Alert>
					</MenuItem>
				) : (
					organizations.map((org, i) => (
						<MenuItem
							key={i}
							dense={false}
							onClick={() => handleClick(`/orgs/${org.handle}`)}
							sx={{ minWidth: '12.25rem' }}>
							<ListItemAvatar>
								<OrgEmblemAvatar id={org.id} size={32} noDropdown />
							</ListItemAvatar>
							<ListItemText>{org.displayName}</ListItemText>
						</MenuItem>
					))
				)}
			</Menu>
		</>
	);
};
