import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import { TypingIndicator } from 'components/ui/typingIndicator';
import { UserChatFootnote, UserChatMessage } from 'middleware-types';
import { ReactNode, useEffect, useRef } from 'react';
import Markdown from 'react-markdown';
import { useAccount } from 'utils/useAccount';
import { CopyButton } from './copyButton';
import { FeedbackButtons } from './feedbackButtons';

interface ChatResponseProps {
	message: UserChatMessage;
	selected: boolean;
	readOnly?: boolean;
	setFootnotes?: React.Dispatch<React.SetStateAction<UserChatFootnote[] | null>>;
}

export const ChatResponse = ({
	message,
	selected,
	readOnly = false,
	setFootnotes,
}: ChatResponseProps) => {
	let text = message.content;
	const footnotes = message.footnotes;

	return (
		<ChatResponseBase selected={selected}>
			<Markdown
				components={{
					p(props) {
						//eslint-disable-next-line
						const { children } = props;
						return <Typography variant="body2">{children}</Typography>;
					},
					li(props) {
						//eslint-disable-next-line
						const { children } = props;
						return (
							<li style={{ fontSize: '0.875rem' }}>
								<Typography variant="body2">{children}</Typography>
							</li>
						);
					},
					a(props) {
						//eslint-disable-next-line
						const { children, href } = props;
						return (
							<Link
								sx={{ 'color': 'navy.500', '&:visited': { color: 'navy.500' } }}
								underline="always"
								target="_blank "
								href={href}>
								{children}
							</Link>
						);
					},
				}}>
				{text}
			</Markdown>
			{footnotes && footnotes.length > 0 && setFootnotes && (
				<Typography pt={2} variant="body2" sx={{ whiteSpace: 'pre-line' }}>
					<Link
						sx={{ 'color': 'navy.500', '&:visited': { color: 'navy.500' } }}
						underline="always"
						target="_blank"
						onClick={() => setFootnotes(footnotes)}>
						(Show {footnotes.length === 1 ? '1 source' : footnotes.length + ' sources'})
					</Link>
				</Typography>
			)}
			<Stack direction="row">
				<FeedbackButtons message={message} readOnly={readOnly} />
				{text && <CopyButton text={text} />}
			</Stack>
		</ChatResponseBase>
	);
};

export const WelcomeMessage = ({ userId }: { userId: string }) => {
	const { account } = useAccount(userId);
	const name = account?.name?.firstName ?? '';
	return (
		<ChatResponseBase>
			<Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
				Hi, {name}. I&apos;m EVO-X, your personal AI assistant. How can I help you?
			</Typography>
		</ChatResponseBase>
	);
};

export const ChatResponseLoading = () => {
	return (
		<ChatResponseBase>
			<Box p={1}>
				<TypingIndicator />
			</Box>
		</ChatResponseBase>
	);
};

const ChatResponseBase = ({
	selected = false,
	children,
}: {
	selected?: boolean;
	children: ReactNode;
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!selected) return;
		containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
	}, [selected]);

	return (
		<Stack direction="row" spacing={1} alignItems="flex-start" ref={containerRef}>
			<Avatar
				src="/img/evoxLogo.svg"
				sx={{ '& img': { objectFit: 'contain', height: '85%' } }}
			/>
			<Box
				borderRadius={1}
				p={1}
				sx={{
					backgroundColor: 'primary.light',
					border: selected ? '2px solid' : undefined,
					borderColor: selected ? 'primary.main' : undefined,
					overflowWrap: 'break-word',
					overflowX: 'auto',
				}}>
				{children}
			</Box>
		</Stack>
	);
};
