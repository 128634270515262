import { useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import gql from 'graphql-tag';
import {
	AssociateInvitationRequest,
	AssociateUpdate,
	Mutation,
	MutationOrganizationAssociateInvitationDeleteArgs,
	MutationOrganizationAssociateDeactivateArgs,
	MutationOrganizationAssociateInvitationSendArgs,
	MutationOrganizationAssociateReactivateArgs,
	MutationOrganizationAssociateUpdateArgs,
	Query,
	QueryAssociatesOrInvitationsArgs,
	QueryRolesArgs,
} from 'middleware-types';
import { PageError } from 'utils/errors';

/**
 * The user status.
 *
 * @enum {number}
 */
export enum Status {
	Invited = 'Invited',
	Declined = 'Declined',
	Active = 'Active',
	Inactive = 'Inactive',
}

/**
 * useOrgAssociatesQuery() - Hook to pull a provided organization's associates.
 *
 * @param {string} organizationId
 * @param {number} pageSize
 * @param {number} page
 * @param {string} sortedBy
 * @param {('Ascending' | 'Descending')} sortDirection
 * @param {string} searchFor
 * @return {*}
 */
export const useOrgAssociatesQuery = (
	organizationId: string,
	pageSize: number,
	page: number,
	sortedBy: string,
	sortDirection: 'Ascending' | 'Descending',
	searchFor: string
) => {
	const toast = useToast();
	const { data, error, loading, refetch } = useQuery<
		Pick<Query, 'associatesOrInvitations'>,
		QueryAssociatesOrInvitationsArgs
	>(
		gql`
			query associatesOrInvitations(
				$organizationId: ID!
				$pageSize: Float!
				$offset: Float!
				$sortedBy: String!
				$sortDirection: String!
				$searchFor: String!
			) {
				associatesOrInvitations(
					organizationId: $organizationId
					pageSize: $pageSize
					offset: $offset
					sortedBy: $sortedBy
					sortDirection: $sortDirection
					searchFor: $searchFor
				) {
					totalCount
					items {
						associateId
						userId
						displayName
						deactivated
						emailAddress
						invitationId
						lastUpdatedUtc
						organizationId
						organizationName
						organizationRoleId
						organizationRoleName
						status
						displayOnProfile
						externalUserDisplayName
						externalUserHandle
					}
				}
			}
		`,
		{
			// checks cache and fetches (important for updating queries after updating a role)
			fetchPolicy: 'cache-and-network',
			variables: {
				organizationId,
				pageSize,
				sortedBy,
				sortDirection,
				searchFor,
				offset: page * pageSize,
			},
			onError: () => {
				toast.push(`An error occurred. Please try again later or contact Support.`, {
					variant: 'error',
				});
				console.log(error);
			},
		}
	);

	const totalCount = data?.associatesOrInvitations?.totalCount ?? 0;
	const associates = data?.associatesOrInvitations.items ?? [];

	return { associates, totalCount, loading, error, refetch };
};

/**
 * useAllOrgRolesQuery(organizationId) - this hook pulls all roles in a provided organization
 *
 * @param {string} organizationId
 * @return {*}  {{
 *     loading: boolean,
 *     roles: { id: string; name: string }[]
 * }}
 */
export const useAllOrgRolesQuery = (
	organizationId: string
): {
	loading: boolean;
	roles: { id: string; name: string }[];
} => {
	const { loading, data, error } = useQuery<Pick<Query, 'roles'>, QueryRolesArgs>(
		gql`
			query orgRolesList(
				$organizationId: ID!
				$pageSize: Float
				$offset: Float
				$sortedBy: String
				$sortDirection: String
			) {
				roles(
					organizationId: $organizationId
					pageSize: $pageSize
					offset: $offset
					sortedBy: $sortedBy
					sortDirection: $sortDirection
				) {
					items {
						id
						organizationId
						name
						permissionKeys
					}
				}
			}
		`,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				organizationId: organizationId,
				pageSize: 1000,
				offset: 0,
			},
		}
	);

	const roles = data?.roles?.items ?? [];

	// Any errors on this modal should be thrown to be caught at the error boundary.
	if (error) {
		throw new PageError([error].filter((p) => p !== undefined));
	}

	return { roles, loading };
};

/**
 * useUpdateAssociateMutation(orgId) - updates an associate.
 *
 * @param {string} organizationId
 * @return {*}
 */
export const useUpdateAssociateMutation = (organizationId: string, associateId: string) => {
	const [updateAssociate, { error }] = useMutation<
		Pick<Mutation, 'organizationAssociateUpdate'>,
		MutationOrganizationAssociateUpdateArgs
	>(
		gql`
			mutation associateUpdate(
				$organizationId: ID!
				$associateId: ID!
				$update: AssociateUpdate!
			) {
				organizationAssociateUpdate(
					organizationId: $organizationId
					associateId: $associateId
					update: $update
				)
			}
		`
	);

	const update = async (values: AssociateUpdate) => {
		return updateAssociate({
			variables: {
				organizationId,
				associateId,
				update: values,
			},
			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'associatesOrInvitations',
				});
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'organizationTeamMembers',
				});
				cache.gc();
			},
		});
	};

	return { update, error };
};

/**
 * useInviteAssociateMutation(orgId) - Send an invitation to an associate.
 *
 * @param {string} organizationId
 * @return {*}
 */
export const useInviteAssociateMutation = (organizationId: string) => {
	const [invAssociate, { error }] = useMutation<
		Pick<Mutation, 'organizationAssociateInvitationSend'>,
		MutationOrganizationAssociateInvitationSendArgs
	>(
		gql`
			mutation invitation($organizationId: ID!, $inviteRequest: AssociateInvitationRequest!) {
				organizationAssociateInvitationSend(
					organizationId: $organizationId
					inviteRequest: $inviteRequest
				)
			}
		`
	);
	const invite = async (values: AssociateInvitationRequest) => {
		return invAssociate({
			variables: {
				organizationId,
				inviteRequest: values,
			},
		});
	};

	return { invite, error };
};

/**
 * useDeleteAssociateInvitationMutation  - Delete an invitation for an associate.
 *
 * @return {*}
 */
export const useDeleteAssociateInvitationMutation = () => {
	const [deleteAssociateInvitation, { error }] = useMutation<
		Pick<Mutation, 'organizationAssociateInvitationDelete'>,
		MutationOrganizationAssociateInvitationDeleteArgs
	>(
		gql`
			mutation OrganizationAssociateInvitationDelete(
				$organizationId: ID!
				$invitationId: ID!
			) {
				organizationAssociateInvitationDelete(
					organizationId: $organizationId
					invitationId: $invitationId
				)
			}
		`
	);
	const deleteInvitation = async (organizationId: string, invitationId: string) => {
		return deleteAssociateInvitation({
			variables: {
				organizationId,
				invitationId,
			},
			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'associatesOrInvitations',
				});
				cache.gc();
			},
		});
	};
	return { deleteInvitation, error };
};

/** useDeactivateAssociatenMutation - Deactivate an associate.
 *
 * @param {string} organizationId
 * @param {string} associateId
 * @return {*}
 */
export const useDeactivateAssociatenMutation = () => {
	const [deactivateOrganizationAssociate, { error }] = useMutation<
		Pick<Mutation, 'organizationAssociateDeactivate'>,
		MutationOrganizationAssociateDeactivateArgs
	>(
		gql`
			mutation organizationAssociateDeactivate($organizationId: ID!, $associateId: ID!) {
				organizationAssociateDeactivate(
					organizationId: $organizationId
					associateId: $associateId
				)
			}
		`
	);
	const deactivateAssociate = async (organizationId: string, associateId: string) => {
		return deactivateOrganizationAssociate({
			variables: {
				organizationId,
				associateId,
			},
			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'associatesOrInvitations',
				});
				cache.gc();
			},
		});
	};

	return { deactivateAssociate, error };
};

export const useReactivateAssociateMutation = (organizationId: string, associateId: string) => {
	const [reactivateOrganizationAssociate, { error }] = useMutation<
		Pick<Mutation, 'organizationAssociateReactivate'>,
		MutationOrganizationAssociateReactivateArgs
	>(
		gql`
			mutation organizationAssociateReactivate(
				$organizationId: ID!
				$associateId: ID!
				$organizationRoleId: ID!
			) {
				organizationAssociateReactivate(
					organizationId: $organizationId
					associateId: $associateId
					organizationRoleId: $organizationRoleId
				)
			}
		`
	);
	const reactivateAssociate = async (organizationRoleId: string) => {
		return reactivateOrganizationAssociate({
			variables: {
				organizationId,
				associateId,
				organizationRoleId,
			},
			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'associatesOrInvitations',
				});
				cache.gc();
			},
		});
	};

	return { reactivateAssociate, error };
};
