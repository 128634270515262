import { ApolloError, MutationHookOptions, useMutation, useQuery } from '@apollo/client';
import {
	AccountTreeOutlined,
	DeleteOutlined,
	EditOutlined,
	ErrorOutlined,
	MoreHorizOutlined,
} from '@mui/icons-material';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import SortableColumnCell from 'components/ui/sortable-column-cell';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import {
	Mutation,
	MutationUserSiteRoleDeleteArgs,
	Query,
	QuerySiteRolesArgs,
	SortDirection,
} from 'middleware-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { DEFAULTPAGESIZES } from 'utils/theme';
import { useSiteUser } from 'utils/useSiteUser';
import { zoneDateOnly } from 'utils/utils';
import { PageContent, PageTitle } from 'components/ui/page';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';

/**
 * useOrgRolesQuery() - hook to pull the provided organization's roles (paged).
 *
 * @param {string} organizationId
 * @param {number} pageSize
 * @param {number} page
 * @param {string} sortedBy
 * @param {('Ascending' | 'Descending')} sortDirection
 * @return {*}
 */
export const useSiteRolesQuery = (
	pageSize: number,
	offset: number,
	sortedBy: string,
	sortDirection: SortDirection,
	searchFor: string
) => {
	const toast = useToast();
	const { data, error, loading, refetch } = useQuery<
		Pick<Query, 'siteRoles'>,
		QuerySiteRolesArgs
	>(
		gql`
			query siteRolesList(
				$pageSize: Float!
				$offset: Float!
				$sortedBy: String!
				$sortDirection: SortDirection!
				$searchFor: String!
			) {
				siteRoles(
					pageSize: $pageSize
					offset: $offset
					sortedBy: $sortedBy
					sortDirection: $sortDirection
					searchFor: $searchFor
				) {
					totalCount
					items {
						id
						name
						isAdminRole
						userCount
						siteRoleCategory {
							name
						}
						lastModifiedUtc
					}
				}
			}
		`,
		{
			// checks cache and fetches (important for updating queries after updating a role)
			fetchPolicy: 'cache-and-network',
			variables: {
				pageSize,
				sortedBy,
				sortDirection,
				offset,
				searchFor,
			},
			onError: () => {
				toast.push(`An error occurred. Please try again later or contact Support.`, {
					variant: 'error',
				});
				console.log(error);
			},
		}
	);

	const totalCount = data?.siteRoles?.totalCount ?? 0;
	const roles =
		data?.siteRoles?.items?.map((r) => ({
			id: r.id,
			isAdminRole: r.isAdminRole,
			name: r.name,
			category: r.siteRoleCategory?.name,
			associatedUsers: r.userCount,
			lastModified: zoneDateOnly(r.lastModifiedUtc),
		})) ?? [];

	return { roles, totalCount, loading, error, refetch };
};

/* Mutation for deleting a site role. */
export const DELETE_SITEROLE = gql`
	mutation UserSiteRoleDelete($id: String!) {
		userSiteRoleDelete(id: $id)
	}
`;

/**
 * useDeleteSiteRoleMutation(userId) - Hook to delete a role.
 *
 * @return {*}
 */
export const useDeleteSiteRoleMutation = (
	options?: MutationHookOptions<
		Pick<Mutation, 'userSiteRoleDelete'>,
		MutationUserSiteRoleDeleteArgs
	>
): {
	deleteRole: (id: string) => Promise<boolean>;
	error: ApolloError | undefined;
	loading: boolean;
} => {
	const toast = useToast();
	const [deleteRoleMut, { error, loading }] = useMutation<
		Pick<Mutation, 'userSiteRoleDelete'>,
		MutationUserSiteRoleDeleteArgs
	>(DELETE_SITEROLE, {
		...options,
	});

	const deleteRole = async (id: string) => {
		return deleteRoleMut({
			variables: {
				id,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`The role was successfully deleted.`, {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteRole, error, loading };
};

/**
 * SiteRoleRow - A single row on the site roles table.
 *
 * @param {SiteRoleRowProps} props
 * @returns
 */
const SiteRoleRow = (props: {
	id: string;
	isAdminRole: boolean | undefined;
	name: string;
	category: string | undefined;
	associatedUsers: number | undefined;
	lastModified: string;
	onDelete: (id: string, name: string) => void;
}): React.JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);
	const { hasPermission } = useSiteUser();

	const handleRowAction = (): void => {
		setAnchorEl(null);
		props.onDelete(props.id, props.name);
	};

	return (
		<TableRow>
			<TableCell size="small">
				<AccountTreeOutlined fontSize="small" />
			</TableCell>
			<TableCell>{props.name}</TableCell>
			<TableCell>{props.category}</TableCell>
			<TableCell>{props.associatedUsers}</TableCell>
			<TableCell>{props.lastModified}</TableCell>
			<TableCell size="small">
				{hasPermission([
					Permission.Site_Role_D,
					Permission.Site_Role_U,
					Permission.Site_Role_R,
				]) && (
					<>
						<IconButton
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							onClick={handleClick}>
							<MoreHorizOutlined />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={(): void => setAnchorEl(null)}>
							{
								/* Material menu's have a hacky way of ref-ing the first child, 
									this breaks when using a wrapping HOC.  Instead, just use the context */
								hasPermission([Permission.Site_Role_U, Permission.Site_Role_R]) && (
									<MenuItem component={Link} to={'./' + props.id}>
										<ListItemIcon>
											<EditOutlined />
										</ListItemIcon>
										<ListItemText>Manage Role</ListItemText>
									</MenuItem>
								)
							}
							{hasPermission(Permission.Site_Role_D) && (
								<MenuItem
									disabled={
										(props.associatedUsers !== undefined &&
											props.associatedUsers > 0) ||
										props.isAdminRole
									}
									onClick={(): void => handleRowAction()}>
									<ListItemIcon>
										<DeleteOutlined />
									</ListItemIcon>
									<ListItemText>Delete Role</ListItemText>
								</MenuItem>
							)}
						</Menu>
					</>
				)}
			</TableCell>
		</TableRow>
	);
};

/**
 * SiteRoles contains the entire site roles page.
 *
 * @returns
 */
const SiteRoles: React.FC = () => {
	const [page, setPage] = useState(0);
	const [isAsc, setIsAsc] = useState<boolean>(true);
	const [sortBy, setSortBy] = useState<string>('Name');
	const [pageSize, setPageSize] = useState<number>(DEFAULTPAGESIZES[0]);
	const { showModal } = useModal();

	const { roles, totalCount, loading, refetch } = useSiteRolesQuery(
		pageSize,
		page * pageSize,
		sortBy,
		isAsc ? SortDirection.Ascending : SortDirection.Descending,
		''
	);
	const { deleteRole } = useDeleteSiteRoleMutation({
		onCompleted: () => refetch(),
	});

	/**
	 * onAction processes an action triggered on a row of the table.
	 * This is used to run the mutation and update the query.
	 *
	 * @param {(string | string)} id
	 * @param {SiteUserRowAction} action
	 */
	const onDelete = (id: string, name: string): void => {
		showModal({
			title: 'Delete Role',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline="Are you sure?"
					informativeContent={`Do you really want to delete the ${name} role? This process cannot be undone.`}
				/>
			),
			actions: (
				<ModalActions>
					<ModalActionButton variant="outlined">Cancel</ModalActionButton>
					<ModalLoadingButton
						color="error"
						variant="contained"
						onClick={async () => deleteRole(id)}>
						Delete Role
					</ModalLoadingButton>
				</ModalActions>
			),
		});
	};

	let rows: React.JSX.Element[] = roles.map((row) => (
		<SiteRoleRow
			key={row.id}
			id={row.id}
			isAdminRole={row.isAdminRole}
			name={row.name}
			category={row.category}
			associatedUsers={row.associatedUsers}
			lastModified={format(row.lastModified, 'MM/dd/yyyy h:mm aaa')}
			onDelete={onDelete}
		/>
	));

	/**
	 * On page changed.
	 *
	 * @param {number} p
	 */
	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	/**
	 * On page size changed.
	 *
	 * @param {React.ChangeEvent<{ value: unknown; }>} e
	 */
	const handlePageSizeChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		const value = parseInt(e.target.value, DEFAULTPAGESIZES[0]);
		setPageSize(value);
		setPage(0);
		refetch();
	};

	/**
	 * Handle column sorting pressed.
	 *
	 * @param {string} dataKey
	 */
	const handleSortClick = (dataKey: string): void => {
		if (dataKey === sortBy) {
			setIsAsc(!isAsc);
		} else {
			setSortBy(dataKey);
		}
	};

	const { hasPermission } = useSiteUser();

	return (
		<>
			<PageTitle title="Site Roles" />
			<PageContent>
				<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
					<CardHeader
						title="Site Roles"
						action={
							hasPermission(Permission.Site_Role_C) && (
								<Button
									component={Link}
									to="/site/roles/create"
									variant="contained"
									color="primary">
									Create Role
								</Button>
							)
						}></CardHeader>
					<CardContent
						sx={{
							flex: '1 1 auto',
							overflow: 'hidden',
						}}>
						<TableContainer sx={{ maxHeight: '100%' }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell size="small" style={{ width: '4%' }}></TableCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="Name"
											onSort={handleSortClick}
											active={'Name' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Role Name
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="SiteRoleCategory.Name"
											onSort={handleSortClick}
											active={'SiteRoleCategory.Name' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Category
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="UserCount"
											onSort={handleSortClick}
											active={'UserCount' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Number of Users
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '22%' }}
											column="LastModifiedUtc"
											onSort={handleSortClick}
											active={'LastModifiedUtc' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Last Edited
										</SortableColumnCell>
										<TableCell
											size="small"
											style={{ width: '10%' }}></TableCell>
									</TableRow>
								</TableHead>
								{loading ? (
									<SkeletonRows rows={pageSize} />
								) : (
									<TableBody>{rows}</TableBody>
								)}
							</Table>
						</TableContainer>
					</CardContent>
					<CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
						<TablePagination
							component={Stack}
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							count={totalCount}
							page={page}
							rowsPerPage={pageSize}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handlePageSizeChange}
						/>
					</CardActions>
				</Card>
			</PageContent>
		</>
	);
};

// Will generate however many placeholders depending on page size
// Actual number of rows may be fewer
const SkeletonRows = (props: { rows: number }): React.JSX.Element => (
	<TableBody>
		{Array(props.rows)
			.fill(null)
			.map((_, i) => {
				return (
					<TableRow key={i}>
						<TableCell colSpan={6}>
							<Skeleton animation="wave" height={'2em'} />
						</TableCell>
					</TableRow>
				);
			})}
	</TableBody>
);

export default SiteRoles;
