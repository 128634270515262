import { SettingsOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Card,
	Container,
	Dialog,
	Drawer,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { useUserDashboardConfigurationSettings } from 'components/pages/dashboard/hooks';
import { LinkCard } from 'components/pages/dashboard/linkCard';
import { presetQuickLinks } from 'components/pages/dashboard/quickLinks/presetQuickLinks';
import { useUserQuickLinks } from 'components/pages/dashboard/quickLinks/userQuickLinkHooks';
import { UserDashboardSettingsModal } from 'components/pages/dashboard/userDashboardSettingsModal';
import { EvoX } from 'components/pages/evoxchat/evox';
import { EvoxLogoIcon } from 'components/ui/icons';
import { PageContent, PageTitle } from 'components/ui/page';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'utils/theme';
import { useIsMediumOrSmallerScreen } from 'utils/useScreenSize';
import { QuickLinks } from '../components/pages/dashboard/quickLinks/quickLinks';

export const SocialUserDashboard = () => {
	const quickLinksMethods = useUserQuickLinks();
	const { settings } = useUserDashboardConfigurationSettings();
	const [openDashboardSettingsModal, setOpenDashboardSettingsModal] = useState(false);

	const isMediumOrSmallerScreen = useIsMediumOrSmallerScreen();

	// keep track of the size of the header
	// this is used to set the height of the header background
	const headerRef = useRef<HTMLDivElement>(null);
	const [headerHeight, setHeaderHeight] = useState(60);
	useEffect(() => {
		if (!headerRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			if (!headerRef.current) return;
			setHeaderHeight(headerRef.current.clientHeight);
		});
		resizeObserver.observe(headerRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	const [evoxOpen, setEvoxOpen] = useState(false);

	const textColor = settings?.primaryColor
		? theme.palette.getContrastText(settings.primaryColor)
		: undefined;

	return (
		<>
			<PageTitle title="Dashboard" />
			<PageContent position="relative" noScroll>
				<Box
					position="absolute"
					zIndex={-100}
					right={0}
					left={0}
					top={0}
					bgcolor={settings?.primaryColor}
					height={headerHeight + 100}
				/>
				<Container maxWidth="xl" disableGutters sx={{ height: '100%' }}>
					<Stack height="100%">
						<Stack
							ref={headerRef}
							spacing={{ xs: 2.5, sm: 3.5 }}
							direction={{ xs: 'column', md: 'row' }}
							alignItems={{ xs: 'stretch', md: 'flex-start' }}
							justifyContent="space-between"
							pt={1}
							pb={{ xs: 3, sm: 4 }}>
							<Box>
								{settings ? (
									<>
										<Typography variant="h1" color={textColor} noWrap>
											{settings.title}
										</Typography>
										<Typography color={textColor}>
											{settings.subTitle}
										</Typography>
									</>
								) : (
									<>
										<Skeleton variant="text" width={140} />
										<Skeleton variant="text" width={140} />
									</>
								)}
							</Box>
							{isMediumOrSmallerScreen ? (
								<Stack spacing={1.5}>
									<Button
										variant="outlined"
										startIcon={<SettingsOutlined />}
										onClick={() => setOpenDashboardSettingsModal(true)}>
										Configure Dashboard Theme
									</Button>
									<Button
										variant="outlined"
										startIcon={<EvoxLogoIcon />}
										onClick={() => setEvoxOpen(true)}>
										Chat with EVOX
									</Button>
								</Stack>
							) : (
								<IconButton
									onClick={() => {
										setOpenDashboardSettingsModal(true);
									}}>
									<SettingsOutlined fontSize="large" htmlColor={textColor} />
								</IconButton>
							)}
						</Stack>
						<Stack flex={1} direction="row" spacing={4} minHeight={0}>
							{!isMediumOrSmallerScreen && (
								<Card sx={{ width: 480 }}>
									<EvoX />
								</Card>
							)}
							<Stack
								flex={1}
								alignSelf="flex-start"
								spacing={{ xs: 3, md: 4 }}
								pb={{ xs: 1.5, sm: 3 }}>
								<Box
									display="grid"
									gridTemplateColumns={{
										xs: 'repeat(2, 1fr)',
										lg: 'repeat(3, 1fr)',
										xl: 'repeat(4, 1fr)',
									}}
									gridAutoRows="1fr"
									gap={2}>
									<LinkCard
										Icon={'img/FT-logo.svg'}
										text="FileTrac"
										link="app/legacy/linked-companies"
										accentColor={settings?.secondaryColor}
									/>
									<LinkCard
										Icon={'/img/conversation-insurance-color-logo.svg'}
										text="Conversation Insurance"
										link="https://app.conversation-insurance.com/"
										accentColor={settings?.secondaryColor}
										externalLink
									/>
									<LinkCard
										Icon={'/img/conversations-logo.png'}
										text="Conversations"
										disabled
									/>
									<LinkCard
										Icon={'/img/marketplace-logo.png'}
										text="Marketplace"
										disabled
									/>
								</Box>
								<QuickLinks
									methods={quickLinksMethods}
									canEdit
									presetQuickLinks={presetQuickLinks}
								/>
							</Stack>
						</Stack>
					</Stack>
				</Container>
			</PageContent>
			{/* EvoX popup for mobile view */}
			{evoxOpen && (
				<Dialog open={evoxOpen} fullScreen>
					<EvoX onClose={() => setEvoxOpen(false)} />
				</Dialog>
			)}
			{isMediumOrSmallerScreen ? (
				<Drawer open={openDashboardSettingsModal} anchor="bottom">
					<UserDashboardSettingsModal
						onClose={() => setOpenDashboardSettingsModal(false)}
					/>
				</Drawer>
			) : (
				<Dialog open={openDashboardSettingsModal} fullWidth>
					<UserDashboardSettingsModal
						onClose={() => setOpenDashboardSettingsModal(false)}
					/>
				</Dialog>
			)}
		</>
	);
};
