import { gql, useMutation, useQuery } from '@apollo/client';
import { useAffiliatedOrgsQuery } from 'components/pages/org-dropdown';
import { useToast } from 'components/ui/toast';
import {
	Connection,
	ConnectionState,
	Mutation,
	MutationSocialOrganizationAcceptConnectionArgs,
	MutationSocialOrganizationCancelConnectionRequestArgs,
	MutationSocialOrganizationDisconnectArgs,
	MutationSocialOrganizationIgnoreConnectionArgs,
	MutationSocialOrganizationRequestConnectionArgs,
	Query,
	QueryOrganizationProfileArgs,
	QueryProfileArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { ALL_USER_ORG_PERMISSIONS } from 'utils/useAssociateUser';
import { ComprehensiveConnectionState, ConnectionStateExtended } from '../../shared/types';
import { ORG_CONNECTIONS_OR_REQUESTS } from '../OrgNetworkScreen/OrgConnectionsOrRequests.graphql';

//types
export interface OrgConnectionInfo {
	connectionId: string | undefined;
	connectionState: ComprehensiveConnectionState;
	orgId: string;
	orgName: string;
	entityId: string;
	entityName: string;
}

// fragments
const ORG_CONNECTION_FIELDS = gql`
	fragment OrgConnectionFields on Connection {
		id
		state
		requestingOrganizationId
		targetOrganizationId
	}
`;

// get an org's profile info, including all its connections
const ORG_PROFILE_CONNECTIONS = gql`
	${ORG_CONNECTION_FIELDS}
	query OrgProfileConnections($organizationId: ID!) {
		organizationProfile(organizationId: $organizationId) {
			id
			displayName
			affiliatedOrganizationConnections {
				...OrgConnectionFields
			}
		}
	}
`;

export const useOrgProfileConnections = (organizationId: string) => {
	const { data, loading } = useQuery<
		Pick<Query, 'organizationProfile'>,
		QueryOrganizationProfileArgs
	>(ORG_PROFILE_CONNECTIONS, {
		variables: { organizationId },
		fetchPolicy: 'cache-and-network',
	});
	return { profile: data?.organizationProfile, loading };
};

// get a user's profile info, including all thier connections
const USER_PROFILE_CONNECTIONS = gql`
	${ORG_CONNECTION_FIELDS}
	query UserProfileConnections($userId: ID!) {
		profile(userId: $userId) {
			id
			name {
				firstName
				lastName
			}
			affiliatedOrganizationConnections {
				...OrgConnectionFields
			}
		}
	}
`;

export const useUserProfileConnections = (userId: string) => {
	const { data, loading } = useQuery<Pick<Query, 'profile'>, QueryProfileArgs>(
		USER_PROFILE_CONNECTIONS,
		{
			variables: { userId },
			fetchPolicy: 'cache-and-network',
		}
	);
	return { profile: data?.profile, loading };
};

export const useOrgConnections = (
	connections: Connection[],
	entityId: string,
	entityName: string
) => {
	const { user } = useSession();

	// get all org permissions
	const { data: orgPermissionsData, loading: orgPermissionsLoading } =
		useQuery<Pick<Query, 'allUserOrgPermissions'>>(ALL_USER_ORG_PERMISSIONS);
	const orgPermissions = orgPermissionsData?.allUserOrgPermissions ?? [];

	// get all orginizations affiliated with the current user
	// filter out invalid permissions and the current org
	const { organizations: _userOrgs, loading: userOrgsLoading } = useAffiliatedOrgsQuery(
		user.userId
	);
	const userOrgs = _userOrgs.filter((org) => {
		if (org.id === entityId) return false;
		const permissions = orgPermissions.find((p) => p.organizationId === org.id);
		if (permissions === undefined) return;
		if (permissions.isOrganizationAdmin) return true;
		if (permissions.permissions === undefined) return false;
		return permissions.permissions.includes(Permission.Org_Social_Network_R);
	});

	// map each org to a connection
	const organizations: OrgConnectionInfo[] = userOrgs.map((org) => {
		let connectionState: ComprehensiveConnectionState = ConnectionStateExtended.NotConnected;
		const connection = connections.find(
			(conn) =>
				conn.requestingOrganizationId === org.id || conn.targetOrganizationId === org.id
		);
		if (connection) {
			if (
				connection.state === ConnectionState.Pending &&
				connection.targetOrganizationId === org.id
			)
				connectionState = ConnectionStateExtended.IncomingRequest;
			else connectionState = connection.state;
		}
		return {
			connectionId: connection?.id,
			connectionState,
			orgId: org.id,
			orgName: org.displayName,
			entityId,
			entityName,
		};
	});

	return {
		organizations,
		loading: orgPermissionsLoading || userOrgsLoading,
	};
};

// request a connection
const ORG_CONNECTION_REQUEST = gql`
	${ORG_CONNECTION_FIELDS}
	mutation OrgConnectionRequest($organizationId: ID!, $userOrOrganizationId: ID!) {
		socialOrganizationRequestConnection(
			organizationId: $organizationId
			userOrOrganizationId: $userOrOrganizationId
		) {
			...OrgConnectionFields
		}
	}
`;

export const useOrgConnectionRequest = () => {
	const toast = useToast();
	const { user } = useSession();

	const [_requestConnection, { loading }] = useMutation<
		Pick<Mutation, 'socialOrganizationRequestConnection'>,
		MutationSocialOrganizationRequestConnectionArgs
	>(ORG_CONNECTION_REQUEST, {
		refetchQueries: [
			ORG_PROFILE_CONNECTIONS,
			USER_PROFILE_CONNECTIONS,
			ORG_CONNECTIONS_OR_REQUESTS,
		],
		awaitRefetchQueries: true,
	});

	const requestConnection = async (organizationId: string, entityId: string) => {
		return await _requestConnection({
			variables: { organizationId, userOrOrganizationId: entityId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection request sent successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		requestConnection,
		loading,
	};
};

// cancel connection request
const CANCEL_ORG_CONNECTION_REQUEST = gql`
	mutation CancelOrgConnectionRequest($organizationId: ID!, $connectionId: ID!) {
		socialOrganizationCancelConnectionRequest(
			organizationId: $organizationId
			connectionId: $connectionId
		)
	}
`;

export const useCancelOrgConnectionRequest = () => {
	const toast = useToast();

	const [_cancelConnectionRequest, { loading }] = useMutation<
		Pick<Mutation, 'socialOrganizationCancelConnectionRequest'>,
		MutationSocialOrganizationCancelConnectionRequestArgs
	>(CANCEL_ORG_CONNECTION_REQUEST, {
		refetchQueries: [
			ORG_PROFILE_CONNECTIONS,
			USER_PROFILE_CONNECTIONS,
			ORG_CONNECTIONS_OR_REQUESTS,
		],
		awaitRefetchQueries: true,
	});

	const cancelConnectionRequest = async (organizationId: string, connectionId: string) => {
		return await _cancelConnectionRequest({
			variables: { organizationId, connectionId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection request cancelled successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		cancelConnectionRequest,
		loading,
	};
};

// remove connection
const REMOVE_ORG_CONNECTION = gql`
	mutation RemoveOrgConnection($organizationId: ID!, $connectionId: ID!) {
		socialOrganizationDisconnect(organizationId: $organizationId, connectionId: $connectionId)
	}
`;

export const useRemoveOrgConnection = () => {
	const toast = useToast();

	const [_removeConnection, { loading }] = useMutation<
		Pick<Mutation, 'socialOrganizationDisconnect'>,
		MutationSocialOrganizationDisconnectArgs
	>(REMOVE_ORG_CONNECTION, {
		refetchQueries: [
			ORG_PROFILE_CONNECTIONS,
			USER_PROFILE_CONNECTIONS,
			ORG_CONNECTIONS_OR_REQUESTS,
		],
		awaitRefetchQueries: true,
	});

	const removeConnection = async (organizationId: string, connectionId: string) => {
		return await _removeConnection({
			variables: { organizationId, connectionId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection removed successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		removeConnection,
		loading,
	};
};

// accept connection
const ACCEPT_ORG_CONNECTION = gql`
	${ORG_CONNECTION_FIELDS}
	mutation AcceptOrgConnection($organizationId: ID!, $connectionId: ID!) {
		socialOrganizationAcceptConnection(
			organizationId: $organizationId
			connectionId: $connectionId
		) {
			...OrgConnectionFields
		}
	}
`;

export const useAcceptOrgConnection = () => {
	const toast = useToast();

	const [_acceptConnection, { loading }] = useMutation<
		Pick<Mutation, 'socialOrganizationAcceptConnection'>,
		MutationSocialOrganizationAcceptConnectionArgs
	>(ACCEPT_ORG_CONNECTION, {
		refetchQueries: [ORG_CONNECTIONS_OR_REQUESTS],
		awaitRefetchQueries: true,
	});

	const acceptConnection = async (organizationId: string, connectionId: string) => {
		return await _acceptConnection({
			variables: { organizationId, connectionId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection accepted successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		acceptConnection,
		loading,
	};
};

// ignore connection
const IGNORE_ORG_CONNECTION = gql`
	mutation IgnoreOrgConnection($organizationId: ID!, $connectionId: ID!) {
		socialOrganizationIgnoreConnection(
			organizationId: $organizationId
			connectionId: $connectionId
		)
	}
`;

export const useIgnoreOrgConnection = () => {
	const toast = useToast();

	const [_ignoreConnection, { loading }] = useMutation<
		Pick<Mutation, 'socialOrganizationIgnoreConnection'>,
		MutationSocialOrganizationIgnoreConnectionArgs
	>(IGNORE_ORG_CONNECTION, {
		refetchQueries: [
			ORG_PROFILE_CONNECTIONS,
			USER_PROFILE_CONNECTIONS,
			ORG_CONNECTIONS_OR_REQUESTS,
		],
		awaitRefetchQueries: true,
	});

	const ignoreConnection = async (organizationId: string, connectionId: string) => {
		return await _ignoreConnection({
			variables: { organizationId, connectionId },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) return false;
				toast.push('Connection declined successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		ignoreConnection,
		loading,
	};
};
