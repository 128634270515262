import { BusinessOutlined, Campaign, Cancel, ForumOutlined } from '@mui/icons-material';
import { Avatar, AvatarProps, Badge, BadgeProps } from '@mui/material';
import { Emblem, EmblemEntityType } from 'middleware-types';
import { useEffect, useRef, useState } from 'react';
import { EmblemDropdown } from './emblem-dropdown/emblem-dropdown';
import { useEmblem, useEmblemSrc } from './hooks';

export interface EmblemAvatarBaseProps extends AvatarProps {
	size?: number;
	noDropdown?: boolean;
	showBadge?: boolean;
}

interface EmblemAvatarProps extends EmblemAvatarBaseProps {
	entityId: string;
	entityType: EmblemEntityType;
}

const _EmblemAvatar = ({
	entityId,
	entityType,
	size = 40,
	noDropdown = false,
	showBadge = true,
	sx,
	...avatarProps
}: EmblemAvatarProps) => {
	const [skip, setSkip] = useState(true);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// if we've already set skip to false, we don't need this observer anymore, so we return early
		if (skip === false) return;
		const observer = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) setSkip(false);
		});
		if (ref.current) observer.observe(ref.current);
		return () => {
			if (ref.current) observer.unobserve(ref.current);
		};
	}, [skip]);

	const { emblem } = useEmblem(entityId, entityType, skip);
	const { src, onError } = useEmblemSrc(emblem);

	let badgeOverlap: BadgeProps['overlap'] = undefined;
	let variant: AvatarProps['variant'] = undefined;
	let children: AvatarProps['children'] = undefined;

	switch (entityType) {
		case EmblemEntityType.User:
			badgeOverlap = 'circular';
			variant = 'circular';
			children = emblem?.extraData?.initials;
			break;
		case EmblemEntityType.Organization:
			badgeOverlap = 'rectangular';
			variant = 'rounded';
			children = <BusinessOutlined sx={{ fontSize: '1.25em' }} />;
			break;
		case EmblemEntityType.Conversation:
			badgeOverlap = 'rectangular';
			variant = 'circular';
			children = <ForumOutlined sx={{ fontSize: '1.25em' }} />;
			break;
	}

	const isGenericSiteUser =
		(emblem?.extraData?.isSiteUser ?? false) && !emblem?.extraData?.siteUserId;

	const props: AvatarProps = {
		ref,
		src: isGenericSiteUser ? '/img/evolve-logo.svg' : src,
		variant,
		onError,
		children,
		sx: {
			'height': size,
			'width': size,
			'fontSize': size / 2,
			'& img': isGenericSiteUser ? { objectFit: 'contain', height: '85%' } : undefined,
			...sx,
		},
		...avatarProps,
	};

	return (
		<EmblemDropdown emblem={emblem} disabled={noDropdown}>
			<Badge
				invisible={!emblem?.deactivated || !showBadge}
				overlap={badgeOverlap}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				badgeContent={
					<Cancel
						color="error"
						sx={{
							fontSize: size * 0.35,
							backgroundColor: 'neutral.50',
							borderRadius: '50%',
						}}
					/>
				}>
				<Avatar
					{...props}
					sx={
						emblem?.deactivated
							? {
									...props.sx,
									border: '2px solid',
									borderColor: 'error.500',
							  }
							: props.sx
					}
				/>
			</Badge>
		</EmblemDropdown>
	);
};

const _AnnoucementAvatar = ({ size = 40, sx, ...avatarProps }: EmblemAvatarBaseProps) => {
	return (
		<Avatar
			{...avatarProps}
			sx={{
				height: size,
				width: size,
				fontSize: size / 2,
				backgroundColor: 'neutral.50',
				color: 'neutral.500',
				...sx,
			}}>
			<Campaign />
		</Avatar>
	);
};

/**
 * wrappers for the emblem avatar component
 */
export const EmblemAvatar = ({
	emblem,
	entityId,
	entityType,
	isSystemAnnouncement,
	...rest
}: EmblemAvatarBaseProps & {
	isSystemAnnouncement?: boolean;
} & (
		| { emblem: Emblem | undefined; entityId?: never; entityType?: never }
		| { emblem?: never; entityId: string; entityType: EmblemEntityType }
	)) => {
	if (entityId) return <_EmblemAvatar entityId={entityId} entityType={entityType} {...rest} />;
	if (emblem === undefined) return null;
	if (isSystemAnnouncement) {
		return <_AnnoucementAvatar {...rest} />;
	}
	return <_EmblemAvatar entityId={emblem.id} entityType={emblem.entityType} {...rest} />;
};

export const UserEmblemAvatar = ({ id, ...rest }: EmblemAvatarBaseProps & { id: string }) => {
	return <_EmblemAvatar entityId={id} entityType={EmblemEntityType.User} {...rest} />;
};

export const OrgEmblemAvatar = ({ id, ...rest }: EmblemAvatarBaseProps & { id: string }) => {
	return <_EmblemAvatar entityId={id} entityType={EmblemEntityType.Organization} {...rest} />;
};
