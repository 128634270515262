import { SettingsOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Container,
	Dialog,
	Drawer,
	IconButton,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { useOrgQuickLinks } from 'components/pages/dashboard/quickLinks/orgQuickLinkHooks';
import { QuickLinks } from 'components/pages/dashboard/quickLinks/quickLinks';
import { useOrgId } from 'components/pages/org/outlet';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { PageContent, PageTitle } from 'components/ui/page';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { theme } from 'utils/theme';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useIsMobile } from 'utils/useScreenSize';
import { useSiteUser } from 'utils/useSiteUser';
import { OrgDashboardSettingsModal } from './editSettingsModal';
import { OrgLinkCards } from './org-link-cards';
import { useOrgDashboardSettings } from './orgDashboardSettingsHooks';

export const OrganizationDashboard = () => {
	const isMobile = useIsMobile();
	const orgId = useOrgId();

	const quickLinksMethods = useOrgQuickLinks(orgId);
	const { settings, loading } = useOrgDashboardSettings(orgId);
	const [openDashboardSettingsModal, setOpenDashboardSettingsModal] = useState(false);

	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const canEdit =
		hasAssociateUserPermission(Permission.Org_Dashboard_U) ||
		hasSiteUserPermission(Permission.Site_OrgDashboard_U);

	const canSendNotification = hasAssociateUserPermission(Permission.Org_Notification_C);

	const textColor = settings?.primaryColor
		? theme.palette.getContrastText(settings?.primaryColor)
		: undefined;

	return (
		<Box position="relative">
			{settings?.primaryColor ? (
				<Box
					position="absolute"
					height={209}
					width="100%"
					zIndex={-100}
					sx={{ backgroundColor: settings.primaryColor }}
				/>
			) : (
				<Skeleton
					variant="rectangular"
					height={209}
					width="100%"
					sx={{ position: 'absolute', zIndex: -100 }}
				/>
			)}
			<PageTitle title="Dashboard" />
			<PageContent>
				<Container maxWidth="lg" disableGutters>
					<Stack spacing={{ xs: 2.5, sm: 5 }}>
						{isMobile ? (
							<>
								<Stack spacing={1.5} pt={1}>
									<Stack direction="row" spacing={2} alignItems="center">
										<OrgEmblemAvatar id={orgId} size={60} />
										{settings?.title ? (
											<Typography variant="h1" color={textColor}>
												{settings?.title}
											</Typography>
										) : (
											<Typography variant="h1" color={textColor}>
												<Skeleton variant="text" />
											</Typography>
										)}
									</Stack>
									<Typography color={textColor}>{settings?.subTitle}</Typography>
								</Stack>
								{canEdit && (
									<Button
										variant="outlined"
										startIcon={<SettingsOutlined />}
										onClick={() => setOpenDashboardSettingsModal(true)}>
										Configure Dashboard Theme
									</Button>
								)}
							</>
						) : (
							<Stack direction="row" spacing={3} alignItems="center" paddingTop={3}>
								<OrgEmblemAvatar id={orgId} size={80} />
								<Box flex={1}>
									<Typography variant="h1" color={textColor}>
										{settings?.title}
									</Typography>
									<Typography color={textColor}>{settings?.subTitle}</Typography>
								</Box>
								{canEdit && (
									<IconButton onClick={() => setOpenDashboardSettingsModal(true)}>
										<SettingsOutlined fontSize="large" htmlColor={textColor} />
									</IconButton>
								)}
							</Stack>
						)}
						<OrgLinkCards settings={settings} />
						<QuickLinks methods={quickLinksMethods} canEdit={canEdit} />
						{isMobile ? (
							<Drawer open={openDashboardSettingsModal} anchor="bottom">
								<OrgDashboardSettingsModal
									orgId={orgId}
									onClose={() => setOpenDashboardSettingsModal(false)}
								/>
							</Drawer>
						) : (
							<Dialog open={openDashboardSettingsModal} fullWidth>
								<OrgDashboardSettingsModal
									orgId={orgId}
									onClose={() => setOpenDashboardSettingsModal(false)}
								/>
							</Dialog>
						)}
					</Stack>
				</Container>
			</PageContent>
		</Box>
	);
};

// const OrgDashboardSkeleton = () => {
// 	return (
// 		<Box position="relative">
// 			<Skeleton
// 				variant="rectangular"
// 				height={209}
// 				width="100%"
// 				sx={{ position: 'absolute', zIndex: -100 }}
// 			/>
// 			<PageTitle title="Dashboard" />
// 			<PageContent>
// 				<Container maxWidth="lg" disableGutters>
// 					<Stack spacing={{ xs: 2.5, sm: 5 }}>
// 						<Stack direction="row" spacing={3} alignItems="center" paddingTop={3}>
// 							<Skeleton
// 								height="4rem"
// 								width="4rem"
// 								sx={{ margin: 'auto' }}
// 								variant="rectangular"
// 							/>
// 							<Box flex={1}>
// 								<Skeleton variant="text" width={150} />
// 								<Skeleton variant="text" width={150} />
// 							</Box>
// 						</Stack>
// 						<Box
// 							display="grid"
// 							gridTemplateColumns={{
// 								xs: 'repeat(2, 1fr)',
// 								md: 'repeat(3, 1fr)',
// 								lg: 'repeat(4, 1fr)',
// 							}}
// 							gridAutoRows="1fr"
// 							gap={2}>
// 							{Array.from({ length: 4 }).map((_, i) => {
// 								return (
// 									<Card key={i}>
// 										<CardContent sx={{ height: '100%' }}>
// 											<Stack
// 												height="100%"
// 												alignItems="center"
// 												justifyContent="center"
// 												spacing={1}>
// 												<Icon fontSize="large" />
// 												<Skeleton
// 													variant="circular"
// 													height={50}
// 													width={50}
// 												/>
// 												<Skeleton variant="text" height={25} width={100} />
// 											</Stack>
// 										</CardContent>
// 									</Card>
// 								);
// 							})}
// 						</Box>
// 					</Stack>
// 				</Container>
// 			</PageContent>
// 		</Box>
// 	);
// };
